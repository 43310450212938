<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-1" title="Tambah User Admin Dinas Pertanian" ok-only ok-title="Accept" hide-footer>
            <b-row>
                <b-col md="12">
                    <b-form-group label="Select User">
                        <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                            :options="option" />
                    </b-form-group>
                    <b-button block variant="primary" size="sm" class="text-white">
                       <h5 class="text-white"> <feather-icon size="20" icon="SaveIcon" /> Simpan</h5>
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>

    </div>
</template>

<script>
    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BRow,
        BCol,
        BFormGroup
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import vSelect from 'vue-select'
    export default {
        components: {
            BButton,
            BModal,
            BAlert,
            BRow,
            BCol,
            BFormGroup,
            vSelect
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        data() {
            return {
                selected: {
                    title: 'Square'
                },
                selected1: {
                    title: 'Aperture',
                    icon: 'ApertureIcon',
                },
                option: [{
                    title: 'Square'
                }, {
                    title: 'Rectangle'
                }, {
                    title: 'Rombo'
                }, {
                    title: 'Romboid'
                }],
                books: [{
                        title: 'Aperture',
                        icon: 'ApertureIcon',
                    },
                    {
                        title: 'Codepen',
                        icon: 'CodepenIcon',
                    },
                    {
                        title: 'Globe ',
                        icon: 'GlobeIcon',
                    },
                    {
                        title: 'Instagram ',
                        icon: 'InstagramIcon',
                    },
                ],
            }
        },
    }
</script>