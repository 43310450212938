<template>
  <div class="match-height">
    <b-row class="mt-1 match-height">
      <b-card>
        <card-opd :rs_data="rs_opd" :admin_data=admin_data :totalRowss="rs_opd.length" @hapusAdmin=hapusAdmin
          @simpanAdmin=simpanAdmin :selected=selected></card-opd>
      </b-card>
    </b-row>
    <add-user></add-user>
  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import axios from '@/config/Axios'
import {
  BRow,
  BCol,
  BCard,
  BAvatar,
  BImg,
  BInputGroup,
  BFormInput,
  BPagination

} from 'bootstrap-vue'
import CardOpd from './component/cardOpd.vue'
import AddUser from './component/addUser.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BImg,
    BInputGroup,
    BFormInput,
    BPagination,
    CardOpd,
    AddUser
  },
  mixins: [Base],
  data() {
    return {
      perPage: 3,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      rs_opd: [],
      admin_data: [],
      selected: {}
    }
  },
  mounted() {
    this.load();
    this.admin();
  },
  methods: {
    async simpanAdmin(val1, val2) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/setup/opd/insert',
        data: {
          id_master_kode_dinas: val2.id_master_kode_dinas,
          user_key: val1.id
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('info', "info", response.data.message);
          self.load();
          self.selected = {}

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async admin() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/setup/opd/admin',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.admin_data = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async hapusAdmin(value) {
      console.log(value.id);
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/setup/opd/delete',
        data: {
          id: value.id
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "Error", response.data.message);
          self.load();
        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/setup/opd/load',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_opd = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  }
}
</script>

<style></style>