<template>
    <b-row>
        <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">

        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label=" " label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(daftarPaket)="data">
                    <b-alert show variant="primary"
                        style="margin-left: -30px; margin-right: -20px;; margin-bottom: -10px;;">
                        <div class="alert-body">
                            <b-row>
                                <b-col md="6">
                                    <small><strong>{{ data.item.kde_dinas }}</strong></small>
                                    <h6><strong>{{ data.item.nama_dinas }}</strong></h6>
                                </b-col>
                                <b-col md="6">
                                    <b-alert variant="" show>
                                        <div class=" ">
                                            <b-row>
                                                <b-col md="9">
                                                    <v-select v-model="selected" class="select-size-sm"
                                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                        @input="savedata(selected, data.item)" label="nm_lengkap"
                                                        :options="admin_data" />
                                                </b-col>
                                                <b-col md="3">
                                                    <b-badge block variant="primary" size="sm">
                                                        <feather-icon icon='UserPlusIcon' size='15'></feather-icon>
                                                        Tambah Admin
                                                    </b-badge>
                                                </b-col>
                                            </b-row>
                                            <h6 v-for="admin in data.item.admin" v-bind:key="admin.id">
                                                <b-badge variant="warning" size="sm" @click="hapusAdmin(admin)">
                                                    <feather-icon icon='TrashIcon' size='15' /> hapus</b-badge>
                                                {{ admin.nm_lengkap }}
                                            </h6>
                                        </div>
                                    </b-alert>
                                </b-col>

                            </b-row>
                        </div>
                    </b-alert>
                </template>
            </b-table>
        </b-col>
        <b-col cols="6">
            <strong>Total Row {{ totalRows }}</strong>
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
import vSelect from 'vue-select'
import {

    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BAlert
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
    components: {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BAlert,
        FeatherIcon,
        vSelect
    },
    props: {
        rs_data: {},
        admin_data: {},
        selected: {},
        totalRowss: {}
    },
    data() {
        return {
            perPage: 5,
            pageOptions: [10, 50, 100],
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [{
                key: 'daftarPaket',
                label: '',
                thStyle: {
                    display: 'none'
                }
            },],

        }
    },
    computed: {
        totalRows: {
            get: function () {
                return this.totalRowss;
            },
            set: function (newValue) {
                return this.totalRowss = newValue;
            }
        },
        items: {
            get: function () {
                return this.rs_data;
            },
            set: function (newValue) {
                return this.rs_data = newValue;
            }
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key
                }))
        },
    },
    mounted() {
        this.totalRows = this.items.length
    },
    emits: ["hapusAdmin", "simpanAdmin"],
    methods: {
        savedata(value, value2) {
            this.$emit("simpanAdmin", value, value2)
        },
        hapusAdmin(value) {
            console.log(value)
            this.$emit("hapusAdmin", value)
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>